<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="提醒设置"
                left-arrow
                @click-left="$router.go(-1)"
                @click-right="toAttention"
        >
            <template #right>
                <Icon name="setting-o" size="18" />
<!--                <van-icon name="setting-o" />-->
            </template>
        </NavBar>
        <div v-for="(val,key) in items" :key="key">
            <Field
                    readonly
                    clickable
                    :name="key"
                    :label="val"
                    :value="results[key]"
                    placeholder="请选择"
                    @click="showPicker[key] = true"
            />
            <Popup v-model="showPicker[key]" position="bottom">
                <Picker
                        show-toolbar
                        :columns="opts(key)"
                        :columns-field-names="customFieldName"
                        @confirm="onConfirm"
                        @cancel="showPicker[key] = false"
                />
            </Popup>
        </div>

        <br>
        <Button round block type="info" @click="addTask" >保存</Button>

        <Loading type="spinner" color="#1989fa" size="24px" vertical v-if="user_empty" style="margin-top: 40%">获取授权中...</Loading>

        <RLFooter/>
    </div>
</template>

<script>

    import {Field ,Loading,Button,Popup,Picker,Icon } from 'vant';
    import {getTjzbTask,saveTjzbTask} from '@/api/tjzb';
    // import {getBaseInfo} from '@/api/patient/baseinfo';
    // import {setToken,removeToken} from '@/utils/auth';
    import {getUser} from '@/api/user';

    export default {
        name: 'QueIdx',
        components: {
            Field,Loading,Button,Popup,Picker,Icon
        },
        data(){
            return {
                active:0,
                followcrfs:[],
                nodata:true,
                user:{
                    userName:'',
                    nickName:'',
                    idCard:'',
                    phone:'',
                    gender:'1',
                    birthday:'',
                    id:'',
                    pid:''
                },
                user_empty:true,
                follow_time:null,
                is_diary:true,
                typeHash:{},
                crfsHash:{},
                activeName:'-1',
                results2:[],
                results:{
                    tjzb_01:null,
                    tjzb_02:null,
                    tjzb_03:null,
                    tjzb_04:null,
                    tjzb_05:null,
                    tjzb_06:null,
                    tjzb_07:null,
                    tjzb_08:null,
                    tjzb_09:null,
                    tjzb_10:null,
                    tjzb_11:null,
                },
                showPicker:{
                    tjzb_01:false,
                    tjzb_02:false,
                    tjzb_03:false,
                    tjzb_04:false,
                    tjzb_05:false,
                    tjzb_06:false,
                    tjzb_07:false,
                    tjzb_08:false,
                    tjzb_09:false,
                    tjzb_10:false,
                    tjzb_11:false,
                },
                items:[]


            };
        },
        computed:{
            customFieldName(){
                let res= {
                    text: 'text',
                    value: 'value',

                }
                return res;
            },

        },
        methods:{
            opts(item){
                let res= [
                    {text:'不提醒',value:item},
                    {text:'每天一次',value:item},
                    {text:'每三天一次',value:item},
                    {text:'每周一次',value:item},
                    {text:'每月一次',value:item},
                    {text:'每三个月一次',value:item},
                    {text:'每六个月一次',value:item},
                    {text:'每年一次',value:item}
                ]
                return res;
            },
            showCrf(idx){
                // this.$router.push({path: '/followcrf/'+idx.id});
                this.$router.push({path: '/crf/',query:{crfname:idx.crfname,sendid:null,itemdataorderid:idx.id,follow_time:null}});
            },
            formaterState(fins){
                if(fins==1){
                    return '未完成';
                }else{
                    return '已完成';
                }
            },
            formaterTime(ctime,uptime){
                let res="";
                if(ctime){
                    res+="创建时间："+this.parseTime(ctime);
                }
                if(uptime){
                    res+="    更新时间："+this.parseTime(uptime);
                }
                return res;
            },
            onConfirm(value){
                console.log(value)
                let item = value.value
                let val = value.text
                this.results[item]= val
                this.showPicker[item] = false;

            },
            addTask(){
                console.log(this.results)
                saveTjzbTask({},{patientid:this.user.id,taskinfo:this.results}).then((response) => {
                    if(response.code==200 ){
                        this.$router.push({path:'/tjzb'});
                        this.$toast.success('操作成功');
                    }else{
                        this.$router.push({path:'/tjzb_task'});
                        this.$toast.fail('操作失败请稍候重试');
                    }
                });
            },
            toAttention(){
                this.$router.push({path:'/tjzb_attention'});

            },

            initData(){
                // let query = this.$route.query;
                // this.follow_time=query.followtime;
                let query = this.$route.query;
                let check_attention=query.check_attention;
                getTjzbTask({patientid:this.user.id}).then((response) => {
                    console.log(response)
                    if(response.code==200 ){
                        this.items = {
                            "tjzb_01": "体重",
                            "tjzb_02": "血压",
                            "tjzb_03": "血糖",
                            "tjzb_04": "总胆固醇",
                            "tjzb_05": "甘油三酯",
                            "tjzb_06": "胆固醇酯",
                            "tjzb_07": "高密度脂蛋白",
                            "tjzb_08": "低密度脂蛋白",
                            "tjzb_09": "血尿酸",
                            "tjzb_10": "血红蛋白",
                            "tjzb_11": "白蛋白"
                        }

                        this.results = response.data[0]
                        this.items = response.data[2]

                        if((check_attention == 1 || check_attention == '1')&&response.data.length > 1){
                            let allfalse = true
                            for(let key in response.data[1]){
                                if(response.data[1][key] == true){
                                    allfalse = false;
                                    break;
                                }
                            }
                            if(allfalse == true){
                                this.$router.push({path:'/tjzb_attention'});

                            }
                        }
                        // this.loading=true;


                    }
                });
                // let follow_time=this.parseTime(new Date(),'{y}-{m}-{d}');

            },
        }
        ,
        created() {
            //生产环境
            // var query = this.$route.query;
            // if(query.code || this.getQueryString('code')){
            //     // console.log("code")
            //     // console.log(query.code)
            //     // console.log(this.getQueryString('code'))
            //     //this.$store.commit('updateOpenid','123456');
            //     this.$store.commit('updateCode',query.code?query.code : this.getQueryString('code'));
            //     setToken(query.code?query.code : this.getQueryString('code'));
            //     getUser().then((res) => {
            //         console.info(res.data);
            //
            //         if(res.data){
            //             this.$store.commit('updateOpenid',res.data.openid);
            //             this.$store.commit('updatePid',res.data.id);
            //             this.$store.commit('updateBaseInfo',res.data);
            //             if(res.data.name !=''){
            //                 this.substatus = true
            //             }
            //             this.user.id=res.data.id;
            //             this.user.name=res.data.name;
            //             this.user.birthday=res.data.birthday;
            //             // this.user.idCard=response.data.idCard;
            //             this.user.telephone=res.data.telephone;
            //             this.user.gender=res.data.gender+'';
            //             this.user.address=res.data.address;
            //             this.user.detail_address=res.data.detail_address;
            //             // this.initBaseInfo();
            //             this.user_empty = false
            //             this.initData()
            //
            //         }
            //     });
            //     //getBaseInfo({}).then((response) => {
            //     //   console.info(response);
            //     // });
            // }else{
            //     removeToken();
            // }
            // 开发环境
            getUser().then((res) => {
                console.info(res.data);

                if(res.data){
                    this.$store.commit('updateOpenid',res.data.openid);
                    this.$store.commit('updatePid',res.data.id);
                    this.$store.commit('updateBaseInfo',res.data);
                    if(res.data.name !=''){
                        this.substatus = true
                    }
                    this.user.id=res.data.id;
                    this.user.name=res.data.name;
                    this.user.birthday=res.data.birthday;
                    // this.user.idCard=response.data.idCard;
                    this.user.telephone=res.data.telephone;
                    this.user.gender=res.data.gender+'';
                    this.user.address=res.data.address;
                    this.user.detail_address=res.data.detail_address;
                    // this.initBaseInfo();
                    this.user_empty = false

                    this.initData();

                }
            });
            // this.initBaseInfo();
            /*初始化列表*/
            // this.initidx();

        },
    }
</script>

<style scoped>

    .is_completed{
        color: #1bde1a;
        /*color: aqua;*/
    }
    .is_uncompleted{
        color:red;
    }

</style>
