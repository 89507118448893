import request from '@/utils/request'

//  获取体检指标列表
export function getTjzb(params) {
    params.dataType='noKey';
    return request({
        url: '/examination/examination_list',
        method: 'get',
        params:params
      })
  }

//  获取体检指标-关注列表
export function getTjzbAttention(params) {
    params.dataType='noKey';
    return request({
        url: '/examination/attention_info',
        method: 'get',
        params:params
      })
  }

//保存体检指标提醒
export function saveTjzbAttention(params,data){
    params.dataType='noKey';
    return request({
        url: '/examination/save_attention',
        method: 'post',
        params:params,
        data:data
    })
}
//获取体检指标-提醒信息
export function getTjzbTask(params){
    params.dataType='noKey';
    return request({
        url: '/examination/examination_task_info',
        method: 'get',
        params:params
    })
}

//保存体检指标-推送频率
export function saveTjzbTask(params,data){
    params.dataType='noKey';
    return request({
        url: '/examination/save_examination_task',
        method: 'post',
        params:params,
        data:data
    })
}